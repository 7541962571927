import { Button, Grid, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch"
import { Paragraph, SectionTitle } from "components/CardFact";
import { PageSection } from "components/page/PageHeader";

const Buy = () => {

  return (
    <Grid container justifyContent="center" width={"100%"}>
      <Grid item xs={12} sm={10}>
        <PageSection>
          <SectionTitle>Get Started</SectionTitle>
          <Paragraph>
            Choose a subscription level to continue.
            You will then be directed to Stripe's secure payment platform to enter your billing details.
            You can cancel you subscription at anytime.
          </Paragraph>
          <form action="/api/v1/stripe/checkout" method="GET">
            <input type="hidden" name="product" value="tier_one__monthly" />
            <Button startIcon={<LaunchIcon />} type="submit">Tier One</Button>
            <Typography variant="caption">
              Everything you need for a busy agent. (This is probably what you want).
            </Typography>
          </form>
          <form action="/api/v1/stripe/checkout" method="GET">
            <input type="hidden" name="product" value="tier_two__monthly" />
            <Button startIcon={<LaunchIcon />} type="submit">Tier Two</Button>
            <Typography variant="caption">
              When you have people to do things for you.
            </Typography>
          </form>
          <form action="/api/v1/stripe/checkout" method="GET">
            <input type="hidden" name="product" value="tier_three__monthly" />
            <Button startIcon={<LaunchIcon />} type="submit">Tier Three</Button>
            <Typography variant="caption">
              When you’re the boss of everyone
            </Typography>
          </form>
          </PageSection>
      </Grid>
    </Grid>
  );
};

export default Buy;
