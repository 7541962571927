import ButtonLink from "components/links/ButtonLink";
import TabularList from "components/tabular-list/TabularList";
import useAdvancedSearch from "components/tabular-list/context";
import { useSearchState } from "components/tabular-list/search-state";
import { AccountType } from "constants/auth.types";
import { PageHeader, PageSubtitle, PageTitle } from "components/page/PageHeader";
import AccountIcon from "@mui/icons-material/AccountBalance";


const columns = [
  {
    id: 'name', 
    numeric: false, 
    label: 'Name', 
    ordering: 'name'
  },
  {
    id: 'actions', 
    numeric: false, 
    label: 'Actions', 
    render: ({row} : {row: AccountType}) => (<ButtonLink to={`/auth/scope?account=${row.id}`}>Login</ButtonLink>)
  },
]

const Accounts = () => {

  const [searchState, updateSearchState] = useSearchState();
  const [state, dispatch] = useAdvancedSearch('account', searchState);

  return (
    <>
      <PageHeader>
        <PageTitle
          text="Accounts"
          Icon={AccountIcon}
        />
        <PageSubtitle text="View all the AgentVA accounts you belong to" />
      </PageHeader>

      <TabularList 
        dispatch={dispatch}
        store={state}
        heading="Accounts"  
        columns={columns}
        searchState={searchState}
        onUpdateSearch={updateSearchState}
      />
    </>
  )
};

export default Accounts;