import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { SvgIconComponent } from "@mui/icons-material";

const factLabelSx = { fontSize: 14 };
const factValueSx = { fontSize: 16 };
export const CardFact = ({ label, children }: { label: string, children: React.ReactNode }) => {
  return (
    <React.Fragment>
      <Typography sx={factLabelSx} color="text.secondary" gutterBottom>
        {label}
      </Typography>

      <Typography sx={factValueSx} variant="body2" component="div" gutterBottom>
        {children}
      </Typography>
    </React.Fragment>
  );
};


export const FactLabel = ({ children }: { children: string }) => {
  return (
    <Typography sx={factLabelSx} color="text.secondary" gutterBottom>
      {children}
    </Typography>
  )
}

export const FactValue = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ mb: 2 }}>{children}</Box>
}

export const FactTextValue = ({
  children,
  emphasis = false,
  emptyText = "",
  gutterBottom = true

}: {
  children: React.ReactNode | null | undefined,
  emphasis?: boolean,
  emptyText?: string,
  gutterBottom?: boolean
}) => {
  let color = emphasis ? "secondary.main" : "text.primary";
  let fontSize = emphasis ? 20 : 16;
  const isEmpty = !children;
  if (isEmpty) color = "text.secondary";

  return (
    <Typography sx={{ fontSize, color, mb: gutterBottom ? 2 : 0 }} >
      {isEmpty ? emptyText : children}
    </Typography>
  )
}

export const ActionableFact = ({ children, actions = [] }: { children: React.ReactNode, actions: React.ReactNode }) => {
  return (
    <Stack sx={{ mb: 2 }} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }}>
      {children}
      <div>
        {actions}
      </div>
    </Stack>
  )
}

export const SectionTitle = ({ children, gutterBottom = true, subtitle = false }: { children: string | React.ReactNode, gutterBottom?: boolean, subtitle?: boolean }) => {

  const sx = {
    fontSize: subtitle ? '1.1rem' : undefined,
    mb: gutterBottom ? 2 : 0
  }
  return <Typography variant="h6" sx={sx}>
    {children}
  </Typography>
}

export const ActionableSectionTitle = ({
  children,
  actions = [],
  Icon = undefined
}: { children: string | React.ReactNode, actions: React.ReactNode, Icon?: SvgIconComponent }) => {

  return (
    <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems={"center"}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {Icon && <Icon/>}
        <SectionTitle gutterBottom={false}>
          {children}
        </SectionTitle>
      </Stack>
      <div>
        {actions}
      </div>
    </Stack>
  )
}

export const Paragraph = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography variant="body2" paragraph >
      {children}
    </Typography>
  )
}

export const EmptyPlaceholder = ({
  text,
  actionText,
  onClick,
  disabled = false,
  loading = false,
}: {
  text: string,
  actionText?: string,
  onClick?: (() => void) | string,
  disabled?: boolean,
  loading?: boolean
}) => {

  const hasAction = actionText && onClick;

  const renderButton = () => {
    let props = typeof (onClick) === "string" ? {
      component: Link,
      to: onClick
    } : {
      onClick: () => onClick && onClick()
    }

    return onClick && (
      <Button disabled={disabled || loading} size="small" sx={{ mt: 2 }} {...props} >
        {actionText}
      </Button>
    )
  }


  return (
    <>
      <Typography sx={{ mt: 1 }} textAlign="center" color="text.disabled" variant="body1" >
        {text}
      </Typography>
      {
        hasAction && (
          <Typography textAlign="center" color="text.disabled" variant="body1" paragraph >
            {renderButton()}
          </Typography>
        )
      }
    </>
  )
}