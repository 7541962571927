
export enum AgentPermissionsEnum {
  EDIT = 'agent:edit',
  INVITE_MEMBER = 'agent:invite_member',
  REVOKE_MEMBER = 'agent:revoke_member',
  CREATE_CONTACT = 'agent:create_contact',
  BROWSE_CONTACTS = 'agent:browse_contacts',
  BROWSE_MEMBERS = 'agent:browse_members',
  BROWSE_SETTINGS = 'agent:browse_settings',
  BROWSE_MESSAGES = 'agent:browse_messages',
  BROWSE_MESSAGE_TEMPLATES = 'agent:browse_message_templates',
  BROWSE_DEAL_TEMPLATES = 'agent:browse_deal_templates',
  BROWSE_LEADS = 'agent:browse_leads',
  CREATE_LEAD = 'agent:create_lead',
  CREATE_DEAL = 'agent:create_deal',
  CREATE_DEAL_TEMPLATE = 'agent:create_deal_template',
  CREATE_DEAL_CALENDAR = 'agent:create_deal_calendar',
  BROWSE_DEALS = 'agent:browse_deals',
  CREATE_MESSAGE_TEMPLATE = 'agent:create_message_template',
  CREATE_EMAIL_TEMPLATE = 'agent:create_email_template'
}

export enum AccountPermissionsEnum {
  CREATE_AGENT = 'account:create_agent',
  CREATE_ACCOUNT_MEMBER = 'account:create_account_member',
  MANAGE_SUBSCRIPTION = 'account:manage_subscription',
}


export enum DealPermissionsEnum {
  EDIT = 'deal:edit',
  DELETE = 'deal:delete',
  EDIT_PHASES = 'deal:edit_phases',
  CREATE_STEP = 'deal:create_step',
  EDIT_ATTRIBUTES = 'deal:edit_attributes',
  EDIT_CONTACTS = 'deal:edit_contacts',
  EDIT_DOCS = 'deal:edit_docs',
  EDIT_MESSAGES = 'deal:edit_messages',
}

export enum DealStepPermissionsEnum {
  EDIT = 'deal_step:edit',
  DELETE = 'deal_step:delete',
  START = 'deal_step:start',
  COMPLETE = 'deal_step:complete',
  BLOCK = 'deal_step:block',
  SKIP = 'deal_step:skip',
  RESET = 'deal_step:reset',
  NOTE = 'deal_step:note',
  SET_DEADLINE = 'deal_step:set_deadline',
}

export enum DealPhasePermissionsEnum {
  ADD_STEP = 'deal_phase:add_step',
  EDIT = 'deal_phase:edit',
}

export enum DealMessagePermissionsEnum {
  DRAFT = 'deal_message:draft',
  PREVIEW = 'deal_message:preview',
  EDIT = 'deal_message:edit',
}

export enum DealTemplatePermissionsEnum {
  DELETE = 'deal_template:delete',
  EDIT = 'deal_template:edit',
}

export enum DealCalendarPermissionsEnum {
  DELETE = 'deal_calendar:delete',
}

export enum MessageDeliveryPermissionsEnum {
  SEND = 'message_delivery:send',
}

export enum NotePermissionsEnum {
  EDIT = 'note:edit',
  DELETE = 'note:delete',
}

export enum EmailTemplatePermissionsEnum {
  EDIT = 'email_template:edit',
  TEST = 'email_template:test',
  DELETE = 'email_template:delete',
}

export enum LeadPermissionsEnum {
  CREATE_LEAD_CONTACT = 'lead:create_lead_contact',
  EDIT = 'lead:edit'
}

export enum LeadContactPermissionsEnum {
  SET_REMINDER = 'lead_contact:set_reminder',
  DELETE = 'lead_contact:delete'
}

export enum LeadContactReminderPermissionsEnum {
  DISMISS = 'lead_contact_reminder:dismiss'
}
