import React from "react";
import {
  ActionableSectionTitle,
  FactLabel,
  FactTextValue,
} from "components/CardFact";
import { useGetOne, useGetOnce } from "hooks/api-hooks";
import {
  AccountSubscriptionType,
  AccountType,
} from "constants/auth.types";
import { hasPermission } from "../../../../permissions";
import { AccountPermissionsEnum } from "constants/permissions.enums";
import { Button } from "@mui/material";
import { PageSection } from "components/page/PageHeader";
import { LocaleDatetime } from "components/locale";
import ExternalLinkIcon from "@mui/icons-material/Launch"

const ManageSubscriptionAction = ({
  account,
  accountSubscription,
}: {
  account: AccountType | null;
  accountSubscription: AccountSubscriptionType | null;
}) => {
  const [result, get, loading] = useGetOne<{ url: string }>("/stripe/portal");

  if (
    !account ||
    !hasPermission(account, AccountPermissionsEnum.MANAGE_SUBSCRIPTION) ||
    !accountSubscription
  )
    return null;

  const handleClick = () =>
    get().then((data) => {
      if (data) {
        window.location.assign(data.url);
      }
    });

  return (
    <Button startIcon={<ExternalLinkIcon/>} variant="contained" disabled={loading || !!result} onClick={handleClick}>
      Manage Billing
    </Button>
  );
};

const SubscriptionInfo = ({ account }: { account: AccountType | null }) => {
  const [accountSubscription, loading] = useGetOnce<AccountSubscriptionType>(
    `account_subscription/current`
  );

  return (
    <PageSection loading={loading || !account}>
      <ActionableSectionTitle
        actions={
          <ManageSubscriptionAction
            key="manage"
            account={account}
            accountSubscription={accountSubscription}
          />
        }
      >Subscription Info</ActionableSectionTitle>

      {accountSubscription ? (
        <React.Fragment>
          <FactLabel>Active From</FactLabel>
          <FactTextValue>
            <LocaleDatetime value={accountSubscription.created_at} />
          </FactTextValue>

          <FactLabel>Last Updated</FactLabel>
          <FactTextValue>
            <LocaleDatetime value={accountSubscription.updated_at} />
          </FactTextValue>

          <FactLabel>Subscription Status</FactLabel>
          <FactTextValue>
            {accountSubscription.status}
          </FactTextValue>

        </React.Fragment>
      ) : (
        "No subscription found"
      )}
    </PageSection>
  );
};

export default SubscriptionInfo;
